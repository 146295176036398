    /* General CSS Setup */
    body{
        font-family: sans-serif;
    }
  
    /* CSS talk bubble */
    .talk-bubble {
        margin-left: 0px;
        display: inline-block;
        position: relative;
        height: auto;
        background-color: white;
    }
    .talk-bubble.me {
        margin-left: 0px;
        display: inline-block;
        position: relative;
        height: auto;
        background-color: #D2F6C5;
    }
    .border{
        border: 8px solid #666;
    }
    .round{
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
    }
    .modal-transparent-bg {
        background-color: transparent !important;
        border: 0px;
    }
    
    /* Right triangle placed top left flush. */
    .tri-right.border.left-top:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -40px;
        right: auto;
        top: -8px;
        bottom: auto;
        border: 32px solid;
        border-color: #666 transparent transparent transparent;
    }
    .tri-right.left-top:after{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -8px;
        right: auto;
        top: 0px;
        bottom: auto;
        border: 8px solid;
        border-color: white transparent transparent transparent;
    }
    
    /* Right triangle, left side slightly down */
    .tri-right.border.left-in:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -40px;
        right: auto;
        top: 30px;
        bottom: auto;
        border: 20px solid;
        border-color: #666 #666 transparent transparent;
    }
    .tri-right.left-in:after{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -20px;
        right: auto;
        top: 38px;
        bottom: auto;
        border: 12px solid;
        border-color: lightyellow lightyellow transparent transparent;
    }
    
    /*Right triangle, placed bottom left side slightly in*/
    .tri-right.border.btm-left:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -8px;
        right: auto;
        top: auto;
        bottom: -40px;
        border: 32px solid;
        border-color: transparent transparent transparent #666;
    }
    .tri-right.btm-left:after{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 0px;
        right: auto;
        top: auto;
        bottom: -20px;
        border: 22px solid;
        border-color: transparent transparent transparent lightyellow;
    }
    
    /*Right triangle, placed bottom left side slightly in*/
    .tri-right.border.btm-left-in:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 30px;
        right: auto;
        top: auto;
        bottom: -40px;
        border: 20px solid;
        border-color: #666 transparent transparent #666;
    }
    .tri-right.btm-left-in:after{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 38px;
        right: auto;
        top: auto;
        bottom: -20px;
        border: 12px solid;
        border-color: lightyellow transparent transparent lightyellow;
    }
    
    /*Right triangle, placed bottom right side slightly in*/
    .tri-right.border.btm-right-in:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: 30px;
        bottom: -40px;
        border: 20px solid;
        border-color: #666 #666 transparent transparent;
    }
    .tri-right.btm-right-in:after{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: 38px;
        bottom: -20px;
        border: 12px solid;
        border-color: lightyellow lightyellow transparent transparent;
    }
    /*
        left: -8px;
        right: auto;
        top: auto;
        bottom: -40px;
        border: 32px solid;
        border-color: transparent transparent transparent #666;
        left: 0px;
        right: auto;
        top: auto;
        bottom: -20px;
        border: 22px solid;
        border-color: transparent transparent transparent lightyellow;
    
    /*Right triangle, placed bottom right side slightly in*/
    .tri-right.border.btm-right:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -8px;
        bottom: -40px;
        border: 20px solid;
        border-color: #666 #666 transparent transparent;
    }
    .tri-right.btm-right:after{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: 0px;
        bottom: -20px;
        border: 12px solid;
        border-color: lightyellow lightyellow transparent transparent;
    }
    
    /* Right triangle, right side slightly down*/
    .tri-right.border.right-in:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: 30px;
        bottom: auto;
        border: 20px solid;
        border-color: #666 transparent transparent #666;
    }
    .tri-right.right-in:after{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -20px;
        top: 38px;
        bottom: auto;
        border: 12px solid;
        border-color: lightyellow transparent transparent lightyellow;
    }
    
    /* Right triangle placed top right flush. */
    .tri-right.border.right-top:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: -8px;
        bottom: auto;
        border: 32px solid;
        border-color: #666 transparent transparent transparent;
    }
    .tri-right.right-top:after{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -8px;
        top: 0px;
        bottom: auto;
        border: 8px solid;
        /* border-color: lightyellow transparent transparent transparent; */
        border-color: lightyellow transparent transparent transparent;
    }

    .tri-right.border.right-top.me:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: -8px;
        bottom: auto;
        border: 32px solid;
        border-color: #666 transparent transparent transparent;
    }
    .tri-right.right-top.me:after{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -8px;
        top: 0px;
        bottom: auto;
        border: 8px solid;
        /* border-color: lightyellow transparent transparent transparent; */
        /* border-color: #42B549 transparent transparent transparent; */
        border-color: #D2F6C5 transparent transparent transparent;
    }
  
    /* talk bubble contents */
    .talktext{
        /* padding: 1em; */
        padding: 0.6em;
        text-align: left;
        line-height: 1.5em;
    }
    .talktext p{
        /* remove webkit p margins */
        -webkit-margin-before: 0em;
        -webkit-margin-after: 0em;
    }

    .j-c-center{
        justify-content: center !important;
    }

    .videocall {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
    /* CODE FOR ATTENDANCE */
    .Attendance {
        box-sizing: border-box;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-family: Arial, Helvetica, sans-serif;
        display: flex;
        /* align-items: center; */
        justify-content: center;
    }
    .AttendanceHeader {
        position: absolute;
        z-index: 9999;
        padding: 20px 20px;
        margin-top: 10px;
        background-color: rgba(226, 226, 226, 0.5); 
        border-radius: 5px;
        font-size: 22px;
        /* font-weight: bold; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: black;
    }
    .AttendanceCamBox {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%; 
        overflow: hidden;
    }
    .AttendanceCamBox video {
        min-width: 100%; 
        min-height: 100%; 
        width: auto;
        height: auto;
        position: absolute;
    }
    @keyframes animateScanIkon {
        0% {
            opacity: 100%;
        }
        50% {
            opacity: 0%;
        }
        100% {
            opacity: 100%;
        }
    }
    .AttendanceCamBoxIkon {
        position: absolute;
        box-sizing: border-box;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .AttendanceFooter {
        box-sizing: border-box;
        width: 80%;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(226, 226, 226, 0.5);  
        border-radius: 5px;
        position: absolute;
        bottom: 0;
        margin-bottom: 10px;
        padding: 10px;
        font-family: Arial, Helvetica, sans-serif;
    }
    .AttendanceFooterH1 {
        font-size: 18px;
        font-weight: bold;
    }
    .AttendanceFooterH2 {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    @media only screen and (max-width: 700px) {
        .Attendance {
            box-sizing: border-box;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            font-family: Arial, Helvetica, sans-serif;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .AttendanceHeader {
            position: absolute;
            top: 0;
            padding: 10px 20px;
            font-size: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 9999;
        }
        .AttendanceCamBox {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            position: relative;
        }
    }